import $ from 'jquery';
//import 'jquery-ui';
import React,{ useState } from 'react';
import { Link,useParams,useNavigate,useLocation } from 'react-router-dom';

window.jQuery = $;
window.$ = $;
global.jQuery = $;
let jQuery=$;
export const Country_ContactExt=()=>{
  return[
    // {"India":"91"},
    // {""}
  ]
}
export const ExperienceYearList=()=>{
  return [
    { value: "less Than 1 year", label: "less Than 1 year" },
    { value: "1+ year", label: "1+ year" },
    { value: "2+ year", label: "2+ year" },
    { value: "3+ year", label: "3+ year" },
    { value: "4+ year", label: "4+ year" },
    { value: "5+ year", label: "5+ year" },
    { value: "6+ year", label: "6+ year" },
    { value: "7+ year", label: "7+ year" },
    { value: "8+ year", label: "7+ year" },
    { value: "9+ year", label: "9+ year" },
    { value: "10+ year", label: "10+ year" },
    { value: "11+ year", label: "11+ year" },
    { value: "12+ year", label: "12+ year" },
    { value: "13+ year", label: "13+ year" },
    { value: "14+ year", label: "14+ year" },
    { value: "15+ year", label: "15+ year" },
]
}
export const LanguageList=()=>{
  return [
    {
     value:'No English',
     label:'No English'
    },
    {
        value: 'Basic',
        label: 'Basic'
    },
    {
        value: 'Intermediate',
        label: 'Intermediate'
    },
    {
        value: 'Advanced',
        label: 'Advanced'
    },
    {
        value:'Native',
        label:'Native'
    },
    {
        value:'Bilingual',
        label:'Bilingual'
    }
]
}

export function ajaxindicatorstart(text) {
  if (jQuery('body').find('#resultLoading').attr('id') != 'resultLoading') {
      jQuery('body').append('<div id="resultLoading" style="display:none"><div><img src="../ajax-loader.gif"><div>' + text + '</div></div><div class="bg"></div></div>');
  }

  jQuery('#resultLoading').css({
      'width': '100%',
      'height': '100%',
      'position': 'fixed',
      'z-index': '10000000',
      'top': '0',
      'left': '0',
      'right': '0',
      'bottom': '0',
      'margin': 'auto'
  });

  jQuery('#resultLoading .bg').css({
      'background': '#000000',
      'opacity': '0.7',
      'width': '100%',
      'height': '100%',
      'position': 'fixed',
      'top': '0',
      'margin-left': '0'
  });

  jQuery('#resultLoading>div:first').css({
      'width': '250px',
      'height': '75px',
      'text-align': 'center',
      'position': 'fixed',
      'top': '0',
      'left': '0',
      'right': '0',
      'bottom': '0',
      'margin': 'auto',
      'font-size': '16px',
      'z-index': '99999999999',
      'color': '#ffffff'

  });

  jQuery('#resultLoading .bg').height('100%');
  jQuery('#resultLoading').fadeIn(300);
  jQuery('body').css('cursor', 'wait');
}

export function ajaxindicatorstop() {
  jQuery('#resultLoading .bg').height('100%');
  jQuery('#resultLoading').fadeOut(300);
  jQuery('body').css('cursor', 'default');
}
export function isWebView() {
  return /(wv)/.test(navigator.userAgent.toLowerCase()) || !!window.Android;
}
export function exportPdf(pageType,uriLoc="") {
  var whe = window.innerHeight;
  var wwid = window.innerWidth;

  let data = document.getElementById("divPrintContent").innerHTML;
  /*
  alert(data);
  var doc = new DOMParser().parseFromString(data, "text/xml");

  alert(doc.getElementsByTagName("<div>")[1]);
  */
  var printWindow = window.open('', '', 'width=' + (wwid - 20) + ',height=' + (whe - 10) + '');
  printWindow.document.write('<html><head><title>Resume</title>');
  //printWindow.document.write('<link rel="stylesheet" href="~/plugins/fontawesome-free/css/all.min.css">');
  printWindow.document.write('<link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css">');
  printWindow.document.write('<link rel="stylesheet" href="https://cdn.datatables.net/1.12.1/css/jquery.dataTables.min.css">');
  printWindow.document.write('<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">');
  //printWindow.document.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/scss/mixins/_grid.scss">');
  //printWindow.document.write('<link rel="stylesheet" href="https://unpkg.com/@webpixels/css@1.1.5/node_modules/bootstrap/scss/mixins/_utilities.scss">');
  
  printWindow.document.write('<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700">');
  printWindow.document.write('<link rel="stylesheet"  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.5.0/css/all.min.css" />');
  printWindow.document.write('<link rel="stylesheet"  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css" />');

  printWindow.document.write('<link rel="stylesheet" href="../comp_resm1_2_3.css">');
  //printWindow.document.write('<link rel="stylesheet" href="../app.css">');
  if(pageType==="1"){
      printWindow.document.write('<style>@page { size: landscape; }table, th, td {border: 0.5px solid;}table{width:100%;border-spacing:0;}</style>');
  }else{
      printWindow.document.write('<style>@page { size: portrait; }table, th, td {border: 0.5px solid;}table{width:100%;border-spacing:0;}</style>');
  }
  
  
  printWindow.document.write('</head><body >');
  printWindow.document.write(data);
  printWindow.document.write('</body></html>');
  printWindow.document.close();
  
  
  setTimeout(function () {
    //alert(JSON.stringify(uriLoc));

    if(typeof window.Android!=="undefined"){
      if(typeof window.Android.setRecentActiveUrl==="function"){
        alert("print");
        window.Android.setRecentActiveUrl(uriLoc.pathname);
      }
    }
   
      //alert("Normal");
      //alert("print");
      printWindow.print();
  
      
  }, 500);
  return false;
}
export function ParseDate(input) {
  // Split the input string by '/'
  const parts = input.split('/');

  // Extract the day, month, and year from the parts
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is zero-based in JavaScript Date
  const year = parseInt(parts[2], 10);

  // Create and return the new Date object
  return new Date(year, month, day);
}

export const DateMonthYear=(inputDate,userFormat=true)=>{
  let fmtDate=new Date()
  if(userFormat===true){
    fmtDate=ParseDate(inputDate);
  }else{
    fmtDate=new Date(inputDate);
  }
  //alert(fmtDate);
  let monthAbbr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let strDate=fmtDate.getDate();
  let strMonth=monthAbbr[fmtDate.getMonth()];
  let strYear = fmtDate.getFullYear();
  let formattedDate = `${strDate}-${strMonth}-${strYear}`;
  return formattedDate;
}
export const MonthYear=(inputDate,userFormat=true)=>{
  let fmtDate=new Date()
  if(userFormat===true){
    fmtDate=ParseDate(inputDate);
  }else{
    fmtDate=new Date(inputDate);
  }
  //alert(fmtDate);
  let monthAbbr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let strMonth=monthAbbr[fmtDate.getMonth()];
  let strYear = fmtDate.getFullYear();
  let formattedDate = `${strMonth}-${strYear}`;
  return formattedDate;

}

export function setResumeTemplateData(existingProf){
  let templateObject={basic:{},
  contact:{},
  skills:[],
  education:[],
  language:[],
  experience:[],
  preference:[],
  other_details:[],
  about_me:{}
}
if(typeof existingProf.candidate_master!=="undefined")
{
  templateObject.basic["first_name"]=existingProf.candidate_master[0]["firstname"];
  templateObject.basic["last_name"]=existingProf.candidate_master[0]["lastname"];
  templateObject.basic["gender"]=existingProf.candidate_master[0]["gender"];
  if(existingProf.candidate_master[0]["profile_pic"]!==null){
    templateObject.basic["profile_pic"]=existingProf.candidate_master[0]["profile_pic"];
  }
  if(existingProf.candidate_master[0]["intro_video"]!==null){
    templateObject.basic["intro_video"]=existingProf.candidate_master[0]["intro_video"]
  }
  if(existingProf.candidate_master[0]["resume"]!==null){
    templateObject.basic["resume"]=existingProf.candidate_master[0]["resume"];
  }
  templateObject.basic["is_premium"]=existingProf.candidate_master[0]["is_premium"];
  
  if(existingProf.candidate_master[0]["is_experienced"]==="true"){
    templateObject["years_exp"]=existingProf.candidate_master[0]["total_experience"];
  }

  templateObject.basic["dob"]=DateMonthYear(existingProf.candidate_master[0]["dob"],false);
  templateObject.contact["mobile"]=existingProf.candidate_master[0]["mobile"];
  templateObject.contact["email"]=existingProf.candidate_master[0]["email"];
  templateObject.contact["address"]=existingProf.candidate_master[0]["city_name"]+", "+
                                    existingProf.candidate_master[0]["state_name"]+", "+
                                    existingProf.candidate_master[0]["country_name"];
  
  

  
  for(let i=0;i<JSON.parse(existingProf.candidate_master[0]["skills"]).length;i++){
    templateObject.skills.push(JSON.parse(existingProf.candidate_master[0]["skills"])[i]["label"]);
  }

  for(let i=0;i<JSON.parse(existingProf.candidate_master[0]["languages"]).length;i++){
    templateObject.language.push(JSON.parse(existingProf.candidate_master[0]["languages"])[i]["label"]);
  }
  

  for(let i=0;i<existingProf.candidate_edu.length;i++){
    if(existingProf.candidate_edu[i]["start"]===null){
      continue;
    }
    let startDate=MonthYear(existingProf.candidate_edu[i]["start"],false);
    let endDate="continue";
    if(existingProf.candidate_edu[i]["is_working"]!==1){
      endDate=MonthYear(existingProf.candidate_edu[i]["end"],false);
    }
    templateObject.education.push({"degree":existingProf.candidate_edu[i]["degree_name"],
      "university":existingProf.candidate_edu[i]["university_name"],
      "duration":""+startDate+" to "+endDate
    });
  }
  

  for(let i=0;i<existingProf.candidate_expe.length;i++){
    let startDate=MonthYear(existingProf.candidate_expe[i]["start"],false);
    let endDate="continue";
    if(existingProf.candidate_expe[i]["is_working"]!=="1"){
      endDate=MonthYear(existingProf.candidate_expe[i]["end"],false);
    }
    templateObject.experience.push({"company_name":existingProf.candidate_expe[i]["company_name"],
      "jobrole_name":existingProf.candidate_expe[i]["jobrole_name"],
      "duration":"Duration: "+startDate+" to "+endDate
    });

  }
  

  

  for(let i=0;i<existingProf.candidate_pref.length;i++){
    templateObject.preference.push({"work":existingProf.candidate_pref[i]["work_name"],
      "job_time":existingProf.candidate_pref[i]["job_name"],
      "job_shift":existingProf.candidate_pref[i]["job_shift"],
      "city_name":existingProf.candidate_pref[i]["city_name"]
    })
  }
}
return templateObject;

}

export function bindEdit() {
  //alert("i m in bind edit");
  let clsRef=this;
  //alert(clsRef.purchaseItem.tt.length);
  $('.editable').unbind('click');
  $(".editable").click(function (event) {
    //alert("i m in click");
      if ($(this).children("input").length > 0)
          return false;

      
      var tdObj = $(this);
      let e_key = $(this).attr("e_key");
      let e_id = $(this).attr("e_id");
      let e_type=$(this).attr("e_type");

      var preText = tdObj.html();
      //alert(preText);
      let _tdObjHeight=tdObj.height();
      let _tdObjWidth=tdObj.width();
      
      let inputObj = $("<input type='text' />");
      if(e_type==="textarea"){
        inputObj=$("<textarea></textarea>");
      }
      tdObj.html("");
      
        //alert(preText+tdObj.height());
          inputObj.width(_tdObjWidth)
              .height(_tdObjHeight)
              .css({ border: "0px", fontSize: "17px","white-space":"pre-wrap","overflow-y":"auto"})
              .val(preText)
              .appendTo(tdObj)
              .trigger("focus")
              .trigger("select");

          //tdObj.html("");
          inputObj.blur(function(event) {
            //alert('Input field lost focus!');
            var text = $(this).val();
            tdObj.html(text);
        });
          inputObj.keyup(function (event) {
              //alert(event.which);
              if (13 == event.which || 9 === event.which) { // press ENTER-key
                  var text = $(this).val();
                  tdObj.html(text);
                  
                  //editLoadDocumentTb(e_id, e_key, text);

              }
              else if (27 == event.which) {  // press ESC-key
                  tdObj.html(preText);
              }
          });
      

    

      inputObj.click(function () {
          return false;
      });
  });
  // for (let de = 0; de < this.purchaseItem.tt.length; de++) {
  //     if(this.canEdit===true){
  //         document.getElementById(this.objName + "_edit_" + (de + 1)).addEventListener("click", (e) => this.refEdit(e, (de)), false);
  //     }
  //     if(this.canDelete===true){
  //         document.getElementById(this.objName + "_de_" + (de + 1)).addEventListener("click", (e) => this.refDel(e, (de)), false);
  //     }
      
  // }
}


export const setFilters=()=>{
  

// Prevent close dropdown filters
	$('.filters_listing_1 .dropdown-menu .filter_type ul').on('click',function(e) {
    e.stopPropagation();
});

//Filters version 2 mobile
$('a.open_filters').on("click", function () {
  //alert("i m in click");
  $('.filter_col').toggleClass('show');
  $('main').toggleClass('freeze');
  $('.layer').toggleClass('layer-is-visible');
});

//Filters collapse
  var $headingFilters = $('.filter_type h4 a');
  $headingFilters.on('click', function () {
      $(this).toggleClass('opened');
  })
  $headingFilters.on('click', function () {
      $(this).toggleClass('closed');
  });
}

export const defaultLoad=()=>{
  $(".search-icon").unbind('click');
  $(".search-icon").on("click", function() {
    $(this).toggleClass('active');
    $('.dropdown_search').slideToggle('medium');
});
}

export const ResetFilters=()=>{
  
    let listCat=document.getElementsByClassName("hcls_flt_job_type");
    for(let i=0;i<listCat.length;i++){
      listCat[i].checked=false;
    }
    
    let listEduLevel=document.getElementsByClassName("hcls_flt_edu_level");
    for(let i=0;i<listEduLevel.length;i++){
        listEduLevel[i].checked=false;
    }
  
    let listBrands=document.getElementsByClassName("hcls_flt_job_shifts");
    for(let i=0;i<listBrands.length;i++){
      listBrands[i].checked=false;
    }
  
    let listPrice=document.getElementsByClassName("hcls_flt_price");
       
        for(let i=0;i<listPrice.length;i++){
          listPrice[i].checked=false;
        }
       // $(".hevent_flt_rightbar").click();
   
    //alert("reset call done");
}
export const SearchFunctionCall=(fltJobShifts,fltEduLevel,fltJobTypes,lowRange,highRange,fltPremium,fltCity,fltSkills)=>{
 
  let listJobShifts=document.getElementsByClassName("hcls_flt_job_shifts");
  for(let i=0;i<listJobShifts.length;i++){
    if(listJobShifts[i].checked===true){
        fltJobShifts=listJobShifts[i].value;
    }
  }

  let listCat=document.getElementsByClassName("hcls_flt_edu_level");
  for(let i=0;i<listCat.length;i++){
    if(listCat[i].checked===true){
      //alert("yes select "+listCat[i].value);
      fltEduLevel=listCat[i].value
    }
  }

 let listPremimum=document.getElementsByClassName("hcls_flt_premium");
 for(let i=0;i<listPremimum.length;i++){
  if(listPremimum[i].checked===true){
    if(fltPremium!==""){
      fltPremium+=",";
    }
    fltPremium+=listPremimum[i].value;
  }
 }

 let listCity=document.getElementsByClassName("hcls_flt_city");
 for(let i=0;i<listCity.length;i++){
  if(listCity[i].value!==""){
    fltCity=listCity[i].value;
  }
 }

 let listSkills=document.getElementsByClassName("hcls_flt_skills");
 for(let i=0;i<listSkills.length;i++){

  if(listSkills[i].value!==""){

    let selectedSkills=JSON.parse(listSkills[i].value);
    fltSkills=selectedSkills["value"];

    // for(let s=0;s<selectedSkills.length;s++){
    //   if(fltSkills!==""){
    //     fltSkills+=",";
    //   }
    //   fltSkills+=selectedSkills[s]["value"];
    // }

  }

 }

  let listBrands=document.getElementsByClassName("hcls_flt_job_type");
  //alert(listBrands.length);
  for(let i=0;i<listBrands.length;i++){
    if(listBrands[i].checked===true){
      if(fltJobTypes!==""){
        fltJobTypes+=",";
      }
      fltJobTypes+=listBrands[i].value;
    }
  }

  let listPrice=document.getElementsByClassName("hcls_flt_price");
 
  for(let i=0;i<listPrice.length;i++){
    if(listPrice[i].checked===true){
     
      let chkRange=listPrice[i].value.trim().split(',');
      if(parseInt(lowRange)==-1){
        lowRange=parseInt(chkRange[0]);
      }
      if(parseInt(lowRange)>parseInt(chkRange[0])){
        lowRange=parseInt(chkRange[0]);
      }
      if(parseInt(highRange)<parseInt(chkRange[1])){
        highRange=parseInt(chkRange[1]);
      }
      if(chkRange[1]==="-11" || chkRange[1]===-11){
        highRange=parseInt(chkRange[1]);
      }

    }
  }
 //alert(highRange);
 let fltPush={"edu_level":fltEduLevel,
  "job_types":fltJobTypes,
  "job_shifts":fltJobShifts,
  "premium":fltPremium,
  "city":fltCity,
  "skills":fltSkills,
  "lowRange":lowRange,
  "highRange":highRange};

  return fltPush;
}
export const RespBkMthdMapp=(obCls,htCls,cBack,groupwhen="")=>{
    
  if(htCls==="reset_filters"){
    
    $(".reset_filters").click(function(){
window.location.reload();
  //     ResetFilters();
  //    let fltPush={"edu_level":"",
  //     "premium":"",
  //     "city":"",
  //     "skills":"",
  //     "brands":"",
  //     "job_shifts":"",
  //     "lowRange":-1,
  //     "highRange":-1};
  
  // cBack(fltPush);//callback
    })
      
    }

    /* filterevents */
  if(htCls==="hevent_flt_rightbar"){
    let fltEduLevel="";
    let fltJobShifts="";
    let fltJobTypes="";

    let fltPremium="";
    let fltCity="";
    let fltSkills="";
    
    let fltPush={};
    let lowRange=-1;
    let highRange=-1;
//alert("i m in filters");
    $(".hcls_flt_job_shifts").change(function(){
        fltJobShifts="";
      if(this.value!==0 && this.value!=="0"){
        // if(fltColors!==""){
        //   fltColors+=",";
        // }
        // fltJobShifts=this.value;
        // fltPush={"edu_level":fltEduLevel,
        // "job_types":fltJobTypes,
        // "job_shifts":fltJobShifts,
        // "lowRange":lowRange,
        // "highRange":highRange};
  
  //cBack(fltPush);//callback
      }
    });
    $("#skills_search").unbind('change');
    $("#skills_search").change(function(){
      //alert("i m in skills");
      //$(".hevent_flt_rightbar").click();
      fltEduLevel="";
      fltJobTypes="";
      fltJobShifts=""
      lowRange=-1;
      highRange=-1;

      fltPremium="";
      fltCity="";
      fltSkills="";
      let fltPush=SearchFunctionCall(fltJobShifts,fltEduLevel,fltJobTypes,lowRange,highRange,fltPremium,fltCity,fltSkills)
     

cBack(fltPush);//callback

    })
    $(".hevent_flt_rightbar").unbind('click');
    $(".hevent_flt_rightbar").click(function(){

      fltEduLevel="";
      fltJobTypes="";
      fltJobShifts=""
      lowRange=-1;
      highRange=-1;

      fltPremium="";
      fltCity="";
      fltSkills="";
      let fltPush=SearchFunctionCall(fltJobShifts,fltEduLevel,fltJobTypes,lowRange,highRange,fltPremium,fltCity,fltSkills)
     

cBack(fltPush);//callback
   
    });
   
     
    
   
  }
}